import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import NextBtn from "../Assets/NextBtn.svg"
import PrevBtn from "../Assets/PrevBtn.svg"

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import './News.css';

function News({ newsData }) {
	const navigate = useNavigate()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  useEffect(() => {
    const swiper = document.querySelector('.swiper_container').swiper;
    swiper.on('slideChange', () => {
      setActiveSlideIndex(swiper.realIndex);
    });
  }, []);

  function handleImageClick(postId) {
		navigate(`./news/${postId}`)
  }

  return (
    <div className="Slider">
      <div className='NewsBlurCircle NewsCircleRight'></div>
      <div className='NewsBlurCircle NewsCircleLeft'></div>
      {newsData && (
        <div className="container">
          <h1 className="heading">News and Updates</h1>
          <div className='ActiveSlideTitle'>
            {Object.values(newsData) &&
              Object.values(newsData)[activeSlideIndex] &&
              Object.values(newsData)[activeSlideIndex].title
              ? Object.values(newsData)[activeSlideIndex].title
              : null}
          </div>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            spaceBetween={-190}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            slidesPerView={2}
            className="swiper_container"
          >
            {Object.entries(newsData)
              .sort((a, b) => b.timestamp - a.timestamp)
              .map(([postId, newsItem], index) => (
                <SwiperSlide key={newsItem.timestamp}>
                  <img
                    src={newsItem.image}
                    alt={`${newsItem.title}`}
                    onClick={() => { if (activeSlideIndex === index) handleImageClick(postId) }}
                  />
                </SwiperSlide>
              ))}
            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <img src={PrevBtn} alt="PrevBtn" />
              </div>
              <div className="swiper-pagination"></div>
              <div className="swiper-button-next slider-arrow">
                <img src={NextBtn} alt="NextBtn" />
              </div>
            </div>
          </Swiper>
        </div>
      )}
			<div className="WelcomeBtns MarginAuto">
				<button onClick={() => navigate("./addNews")}>Add New Post</button>
			</div>
    </div>
  );
}

export default News;