import React, { useEffect, useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getData, postData } from '../../Fetch';
import { useNavigate } from 'react-router-dom';

function AddLevelPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Preview the image locally
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // Confirm save
    const confirmSave = window.confirm('Are you sure you want to save this level?');

    if (confirmSave) {
      try {
        if (!title || !image) {
          alert('Please provide a title and upload an image.');
          return;
        }

        // Generate a unique file name for storage
        const fileName = `level-${Date.now()}`;

        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(storage, `website/thumbnails/${fileName}`);

        // Create a new upload task with progress updates
        const uploadTask = uploadBytesResumable(storageRef, image);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error during upload:', error);
            alert('Error during upload. Please try again.');
          },
          async () => {
            try {
              // Upload completed successfully, get the download URL
              const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
              const lvls = await getData("website/levels")
              // Save level data to the Realtime Database
              const levelData = {
                order: lvls ? Object.keys(lvls).length + 1 : 1,
                name: fileName,
                thumbnail: imageURL,
                title: title,
                body: body,
                public: lvls && Object.keys(lvls).length !==0? null : true
              };

              await postData('website/levels', levelData);

              alert('Level saved successfully!');
              navigate(-1)
            } catch (uploadError) {
              console.error('Error saving level:', uploadError);
              alert('Error saving level. Please try again.');
              navigate(-1)
            }
          }
        );
      } catch (error) {
        console.error('An error occurred:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const handleCancel = () => {
    // Confirm discard changes
    const confirmDiscard = window.confirm('Discard changes and go back?');

    if (confirmDiscard) {
      navigate("/website")

    }
  };

  return (
    <form onSubmit={handleSave}>
      <h1>Add Level</h1>
      <label>
        <input placeholder='Level Title' className='SectionTitle inputStyle' required type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      </label>
      <br />
      <label>
        <textarea placeholder='Level Description' className='CourseLevelBody AreaStyle' type="text" value={body} onChange={(e) => setBody(e.target.value)} />
      </label>
      <br />
      <label>
        <input required type="file" accept="image/*" onChange={handleImageChange} />
      </label>
      <br />
      {image && (
        <div>
          {/* Display the image preview */}
          <img className='Content' src={imagePreview} alt="Level Preview" />
        </div>
      )}
      <br />
      <div className="WelcomeBtns MarginAuto MarginTop">
      <button type='button' onClick={handleCancel}>Cancel</button>
      <button className='DeleteBtn' type='submit'>Save</button>
      </div>
      <div>{uploadProgress > 0 && `Uploading ${uploadProgress.toFixed(0)}%`}</div>
    </form>
  );
}

export default AddLevelPage;