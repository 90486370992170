import React, { useEffect, useState } from 'react';
import { getData, deleteSecondaryAdmins } from '../../Fetch';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import "./UsersList.css"
import AddBtn from "../../Assets/AddBtn.svg"
import Loading from '../../Components/Loading';

const AdminsList = () => {
  const [admins, setAdmins] = useState(null);
  const [filteredAdmins, setFilteredAdmins] = useState(null);
  const [myData, setMyData] = useState('');
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [authPassword, setAuthPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminsData = await getData(`/admins`);

        if (adminsData != null) {
          const admin = await getData(`admins/${localStorage.getItem('aid')}/`);
          setMyData(admin);

          const adminArray = Object.entries(adminsData || {}).map(([id, data]) => ({
            id,
            ...data,
          }));
          setAdmins(adminArray);
          setFilteredAdmins(adminArray);
          setLoading(false)
        } else {
          console.log('No admins found');
          setAdmins([]);
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching admins:', error);
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  const handleDeleteAll = async () => {
    setIsDeleteAllModalOpen(true);
  };

  const handleConfirmDeleteAll = async () => {
    if (authPassword !== myData.password) {
      setAuthError('Enter the correct password!');
      return;
    }

    try {

      setLoading(true)

      // Get the current user
      const user = getAuth().currentUser;

      if (!user) {
        // Handle the case when the user is not authenticated
        console.log('User not authenticated. Please log in.');
        setLoading(false)
        return;
      }

      // Call the Cloud Function to delete secondary admins
      const response = await deleteSecondaryAdmins();

      if (response.success) {
        alert('Secondary admins deleted successfully!');
        // Refresh the admins list
        const refreshedAdminsData = await getData(`/admins`);

        // Add null check for refreshedAdminsData
        if (refreshedAdminsData != null) {
          const refreshedAdminArray = Object.entries(refreshedAdminsData).map(([id, data]) => ({
            id,
            ...data,
          }));
          setAdmins(refreshedAdminArray);
          setFilteredAdmins(refreshedAdminArray);
          setIsDeleteAllModalOpen(false);
          setAuthError('');
          setAuthPassword('');
          setLoading(false)
        } else {
          console.error('Error fetching refreshed admins data:', refreshedAdminsData);
          setAuthError('Error fetching refreshed admins data');
          setAuthPassword('');
          setLoading(false)
        }
      } else {
        setAuthError(response.message || 'Error deleting secondary admins.');
        setAuthPassword('');
        setLoading(false)
      }
    } catch (error) {
      console.error('Error authenticating user or deleting admins:', error);
      setAuthError('Something went wrong, refresh the page and try again');
      setAuthPassword('');
      setLoading(false)
    }
  };


  const handleCancelDeleteAll = () => {
    setIsDeleteAllModalOpen(false);
    setAuthPassword('');
    setAuthError('');
  };

  const handleSearch = (query) => {
    const lowerCaseQuery = query?.toLowerCase();
    const filtered = admins?.filter(
      (admin) =>
        admin?.name?.toLowerCase().includes(lowerCaseQuery) ||
        admin?.role?.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredAdmins(filtered);
  };

  if (loading) return <Loading />

  return (
    <div>
      <div className='SectionTitle MarginTop'>Admins List</div>
      <div className='SearchContainer'>
        <input
          className='ListSearch'
          type="text"
          id="searchQuery"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Type to search..."
        />
        <Link to={'/admins/add'}>
          <img className='AddBtn' src={AddBtn} alt="AddBtn" />
        </Link>
      </div>
      {filteredAdmins ? (
        filteredAdmins.length > 0 ? (
          <div className='UsersListContainer'>
            {filteredAdmins.map((admin) => {
             if(admin.name !== "Mahmoud Owaida") return <Link className='UsersListItem' key={admin.id} to={`/admins/${admin.id}`}>
                <div>{admin.name}</div>
                <div>#{admin.role}</div>
              </Link>
            })}
          </div>
        ) : (
          <p>No matching admins found.</p>
        )
      ) : (
        <p>Loading admins...</p>
      )}

      {myData && myData.role === 'super' && (
        <div className='FlexColumn MarginTop'>
          <button className='AppBtn DeleteBtn' onClick={handleDeleteAll} disabled={!admins || admins.length === 0}>
            DELETE ALL
          </button>
        </div>
      )}

      {/* Delete All Modal */}
      {isDeleteAllModalOpen && (
        <form>
          <div className='MarginTop'>
            <input
              className='AuthInput'
              placeholder='Enter Password:'
              type="password"
              id="authPassword"
              value={authPassword}
              onChange={(e) => setAuthPassword(e.target.value)}
              required
            />
            {authError && <p style={{ color: 'red' }}>{authError}</p>}
            <div className="WelcomeBtns MarginAuto MarginTop">
              <button type="button" onClick={handleCancelDeleteAll}>Cancel</button>
              <button className='DeleteBtn' type="button" onClick={handleConfirmDeleteAll}>Confirm</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminsList;
