import React from 'react'
import Tri from "../Assets/Tri.svg";
import { Draggable } from 'react-beautiful-dnd';

export default function VideoCard({vid, index, video, navigate}) {
	return (
		<Draggable draggableId={vid} index={index}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="VideoListTitleContainer"
					onClick={()=> navigate(`./vid/${vid}`)}
				>
					<div className="VideoListCircle">{index + 1}</div>
					<div>
						<div className="VideoListTitle">{video.title}</div>
						<div className="WatchVideo">
							<div>Watch Video</div>
							<img src={Tri} alt="" />
						</div>
					</div>
				</div>
			)}
		</Draggable>
	)
}
