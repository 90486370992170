import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getData } from './Fetch';
import { initializeApp } from 'firebase/app';

import HomePage from './Pages/KnightsSystem/HomePage';
import AdminsList from './Pages/AdminPages/AdminsList';
import TraineesList from './Pages/AdminPages/TraineesList';
import Menu from './Pages/AdminPages/Menu';
import Auth from './Pages/Auth';
import AdminProfile from './Pages/AdminPages/AdminProfile';
import TraineeProfile from './Pages/AdminPages/TraineeProfile';
import AddTrainee from './Pages/AdminPages/AddTrainee';
import AddAdmin from './Pages/AdminPages/AddAdmin';
import LevelPage from './Pages/KnightsSystem/LevelPage';
import NewsPage from './Pages/KnightsSystem/NewsPage';
import VideoPage from './Pages/KnightsSystem/VideoPage';
import AddNewsPage from './Pages/KnightsSystem/AddNewsPage';
import AddLevelPage from './Pages/KnightsSystem/AddLevelPage';
import AddVideoPage from './Pages/KnightsSystem/AddVideoPage';
import EditLevelPage from './Pages/KnightsSystem/EditLevelPage';
import EditVideoPage from './Pages/KnightsSystem/EditVideoPage';
import Loading from './Components/Loading';
import Footer from './Components/Footer';
import Header from './Components/Header';

const firebaseConfig = {
  apiKey: "AIzaSyAN4OBSD0OYlmqCWglorlF6gJn2AdSTqSk",
  authDomain: "gc-edu.firebaseapp.com",
  databaseURL: "https://gc-edu-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gc-edu",
  storageBucket: "gc-edu.appspot.com",
  messagingSenderId: "152395550515",
  appId: "1:152395550515:web:c5aaca1fb6bcb30469b846",
  measurementId: "G-VPC31MGTRW"
};

const firebaseApp = initializeApp(firebaseConfig);

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    const aid = localStorage.getItem("aid")
    if (!aid) {
      setIsAuth(false);
      setLoading(false);
      return
    }

    const auth = getAuth(firebaseApp);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const uid = user.uid;
          const data = await getData(`admins/${uid}`);

          if (data) {
            setAdminData(data);
            setIsAuth(true);
          } else {
            setIsAuth(false);
            handleLogout()
            alert("No admin data")
          }
        } catch (error) {
          setIsAuth(false);
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setIsAuth(false);
        setLoading(false);
        localStorage.removeItem("aid")
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setIsAuth, setAdminData]);

  const handleLogout = async () => {
    setLoading(true)
    const auth = getAuth(firebaseApp);
    try {
      await signOut(auth).then(() => {
        setIsAuth(false);
        setAdminData(null);
        localStorage.removeItem('aid');
        // navigate("/", { replace: true });
        alert("Logout Successful")
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const adminRoutes = {
    super: [
      //AdminPages
      { path: '/', element: <Menu /> },

      { path: '/admins', element: <AdminsList /> },
      { path: '/admins/:aid', element: <AdminProfile /> },
      { path: '/admins/add', element: <AddAdmin /> },
      { path: '/trainees', element: <TraineesList /> },
      { path: '/trainees/:tid', element: <TraineeProfile /> },
      { path: '/trainees/add', element: <AddTrainee /> },

      //WebsitePages
      { path: '/website', element: <HomePage /> },
      { path: '/website/addLevel', element: <AddLevelPage /> },
      { path: '/website/lvl/:lid', element: <LevelPage /> },
      { path: '/website/lvl/:lid/editLevel', element: <EditLevelPage /> },
      { path: '/website/lvl/:lid/addVideo', element: <AddVideoPage /> },
      { path: '/website/lvl/:lid/vid/:vid', element: <VideoPage /> },
      { path: '/website/lvl/:lid/vid/:vid/editVideo', element: <EditVideoPage /> },

      { path: '/website/addNews', element: <AddNewsPage /> },
      { path: '/website/news/:nid/', element: <NewsPage /> },
    ],

    secondary: [
      { path: '/', element: <Navigate to="/trainees" replace /> },

      { path: '/trainees', element: <TraineesList /> },
      { path: '/trainees/:tid', element: <TraineeProfile /> },
      { path: '/trainees/add', element: <AddTrainee /> },
    ],
  };

  const userRoutes = [
    { path: '/', element: <Auth setIsAuth={setIsAuth} setAdminData={setAdminData} /> },
  ];

  const routes = isAuth
    ? adminData.role === 'super'
      ? adminRoutes.super
      : adminRoutes.secondary
    : userRoutes;

    
  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Header Role={adminData ? adminData.role : null} isAuth={isAuth} handleLogout={handleLogout} />
      <div className="App">
        <div className='BlurCircle TopRight'></div>
        <div className='BlurCircle TopLeft'></div>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          {/* Catch-all route for wrong routes */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;