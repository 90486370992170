import React, { useState } from 'react'
import Logo from "../Assets/Logo.png";
import QuickLinksIcon from "../Assets/QuickLinksIcon.png";
import "./Header.css"
import { useLocation, useNavigate } from 'react-router-dom';
import closeMenu from "../Assets/close-menu.png";

const DarkHeaderMenuBG = ({ onClick }) => {
	const handleClick = (e) => {
		e.stopPropagation(); // Prevent the click event from reaching the parent
		onClick();
	};

	return <div className='DarkHeaderMenuBG' onClick={handleClick} />;
};

export default function Header({ Role, isAuth, handleLogout }) {
	const navigate = useNavigate()
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);

	const menuItems = [
		{ name: "Website", to: "/website" },
		{ name: "Trainees", to: "/trainees" },
		{ name: "Admins", to: "/admins" }
	];

	const handleShowMenu = () => {
		setShowMenu(!showMenu)
	}

	return (
		<>
			<div className='Header'>
				<img onClick={() => navigate("/")} className='HeaderLogo' src={Logo} alt="Logo" />
				{isAuth ?
					location.pathname !== "/" && Role === "super" ?
						<div className='HeaderMenu'>
							<div className='QuickLinks'>
								{menuItems.map((item) => <div onClick={() => navigate(item.to)} key={item.name}>{item.name}</div>)}
							</div>
							<img onClick={handleShowMenu} src={QuickLinksIcon} className='QuickLinksIcon' alt="QuickLinksIcon" />
							{location.pathname !== "/" && <button onClick={() => { handleLogout(); navigate("/"); }} className='HeaderBtn'>Logout</button>}
						</div>
						:
						<button onClick={() => { handleLogout(); navigate("/"); }} className='HeaderBtn'>Logout</button>
					: null
				}
			</div>
			<>
				{showMenu && <DarkHeaderMenuBG onClick={handleShowMenu} />}
				<div className={`HeaderMenuItems ${showMenu ? 'open' : ''}`}>
					<div onClick={handleShowMenu} className='CloseHeaderMenuItems'>
						<img src={closeMenu} alt='' />
					</div>
					{menuItems.map((item) => <div className="HeaderMenuItem" onClick={() => { navigate(item.to); setShowMenu() }} key={item.name}>{item.name}</div>)}
				</div>
			</>
		</>
	)
}
