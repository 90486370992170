import React from 'react'
import { Link } from 'react-router-dom'
import "./Menu.css"
import WebsiteImg from "../../Assets/WebsiteImg.svg"
import AdminsImg from "../../Assets/AdminsImg.png"
import TraineesImg from "../../Assets/TraineesImg.svg"

function Menu() {
	return (
		<div>
			<div className='AuthTitle'>Admin Menu</div>
			<div className='AdminMenuContainer'>
				<Link className='AdminMenuItem' to={"/website"}>
				<img src={WebsiteImg} alt="ItemImg" />
					<div>Website</div>
				</Link>
				<Link className='AdminMenuItem' to={"/trainees"}>
				<img src={TraineesImg} alt="ItemImg" />
					<div>Trainees</div>
				</Link>
				<Link className='AdminMenuItem' to={"/admins"}>
				<img src={AdminsImg} alt="ItemImg" />
					<div>Admins</div>
				</Link>
			</div>
		</div>
	)
}

export default Menu
