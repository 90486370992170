import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getData, postData } from '../../Fetch';
import { useNavigate, useParams } from 'react-router-dom';

const Token = 'dd5a525f6c393f9d177696acd54d5dd4';

function AddVideoPage() {
  const navigate = useNavigate();
  const { lid } = useParams();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [statusMessage, setStatusMessage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);

    // Preview the video locally
    const reader = new FileReader();
    reader.onloadend = () => {
      setVideoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // Confirm save
    const confirmSave = window.confirm('Are you sure you want to save this video?');

    if (confirmSave) {
      try {
        if (!title || !video) {
          alert('Please provide a title and upload a video.');
          return;
        }

        setIsUploading(true);

        try {
          // Step 2: Get upload link from Vimeo
          setStatusMessage('Getting upload link from Vimeo...');
          const uploadLinkResponse = await axios.post(
            'https://api.vimeo.com/me/videos',
            {
              upload: {
                approach: 'tus',
                size: video.size
              }
            },
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json'
              }
            }
          );

          const uploadLink = uploadLinkResponse.data.upload.upload_link;

          // Step 3: Upload the video file to Vimeo using the upload link with progress tracking
          setStatusMessage('Uploading video...');
          await axios.patch(uploadLink, video, {
            headers: {
              'Content-Type': 'application/offset+octet-stream',
              'Upload-Offset': 0,
              'Tus-Resumable': '1.0.0',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setStatusMessage(`Uploading video: ${percentCompleted}%`);
            }
          });

          const videoUri = uploadLinkResponse.data.uri;
          const videoId = videoUri.split('/videos/').pop();
          const videoLink = `https://player.vimeo.com/video/${videoId}`;

          // Step 4: Set the video name on Vimeo
          setStatusMessage('Setting video name...');
          const videoName = `video-${videoId}`;
          await axios.patch(
            `https://api.vimeo.com${videoUri}`,
            {
              name: videoName
            },
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json'
              }
            }
          );

          // Step 5: Save the video link to Firebase Realtime Database
          setStatusMessage('Saving video link to database...');
          const order = await getData(`levels/${lid}/videos`);
          const videoData = {
            order: order ? Object.keys(order).length + 1 : 1,
            id: videoId,
            video: videoLink,
            title: title,
            body: body
          };

          await postData(`levels/${lid}/videos`, videoData);

          alert('Video saved successfully!');
          navigate(`/website/lvl/${lid}`);
        } catch (error) {
          console.error('Error uploading video:', error);
          alert('Error uploading video');
        } finally {
          setIsUploading(false);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        alert('An error occurred. Please try again.');
        setIsUploading(false);
      }
    }
  };

  const handleCancel = () => {
    // Confirm discard changes
    const confirmDiscard = window.confirm('Discard changes and go back?');

    if (confirmDiscard) {
      navigate(`/website/lvl/${lid}`);
    }
  };

  return (
    <form onSubmit={handleSave}>
      <h1>Add Video</h1>
      <label>
        <input placeholder='Level Title' className='SectionTitle inputStyle' required type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      </label>
      <br />
      <label>
        <textarea placeholder='Level Description' className='CourseLevelBody AreaStyle' type="text" value={body} onChange={(e) => setBody(e.target.value)} />
      </label>
      <br />
      <label>
        <input required type="file" accept="video/*" onChange={handleVideoChange} />
      </label>
      <br />
      {video && (
        <div>
          {/* Display the video preview */}
          <video className='VideoPagePlayer' src={videoPreview} alt="Video Preview" />
        </div>
      )}
      <br />
      <div className="WelcomeBtns MarginAuto MarginTop">
        <button type='button' onClick={handleCancel} disabled={isUploading}>Cancel</button>
        <button className='DeleteBtn' type='submit' disabled={isUploading}>
          {isUploading ? 'Uploading...' : 'Save'}
        </button>
      </div>
      <div>{statusMessage}</div>
    </form>
  );
}

export default AddVideoPage;