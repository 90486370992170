import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getData, deleteData, putData } from '../../Fetch';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Loading from '../../Components/Loading';
import "./LevelPage.css"
import axios from 'axios';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import VideoCard from '../../Components/VideoCard';

const Token = 'dd5a525f6c393f9d177696acd54d5dd4';

function LevelPage() {
  const navigate = useNavigate();
  const { lid } = useParams();
  const [levelDetails, setLevelDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const [videosDetails, setVideosDetails] = useState(null);
  const courseLevelContainerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
    const fetchLevelDetails = async () => {
      try {
        const data = await getData('website');
        const levelDetails = data.levels[lid];

        const videos = await getData(`levels/${lid}/videos`);

        if (levelDetails) {
          setLevelDetails(levelDetails);
          setVideosDetails(videos);
          setLoading(false);
        } else {
          console.error(`Level with id ${lid} not found.`);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching level details:', error);
        setLoading(false);
      }
    };

    fetchLevelDetails();
  }, [lid]);

  const handleDelete = async () => {
    // Confirm deletion
    const confirmDelete = window.confirm('Are you sure you want to delete this level?');

    if (confirmDelete) {
      try {
        await deleteLevelData(levelDetails);
        alert('Level deleted successfully!');
        navigate(-1);
      } catch (error) {
        alert('Error deleting the level. Please try again.');
      }
    }
  };

  const deleteLevelData = async (levelDetails) => {
    try {
      // Create a storage reference
      const storage = getStorage();

      // Step 1: Deleting thumbnail
      setStatusMessage("Deleting thumbnail..")
      if (levelDetails && levelDetails.thumbnail) {
        try {
          const levelImageRef = ref(storage, `website/thumbnails/${levelDetails.name}`);
          await deleteObject(levelImageRef);
          console.log("Thumbnail deleted")
        } catch (error) {
          console.log("error deleting level's thumbnail from storage", error);
        }
      }

      // Step 2: Deleting level videos & attachments
      setStatusMessage("Deleting level videos & attachments..")
      if (videosDetails) {
        Object.entries(videosDetails).map(async ([vid, videoDetails]) => {
          if (videoDetails && videoDetails.id) {
            try {
              await axios.delete(
                `https://api.vimeo.com/videos/${videoDetails.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${Token}`,
                  }
                }
              );
            } catch (deleteError) {
              console.error('Error deleting old video:', deleteError);
              setStatusMessage('Failed to delete old video, continuing with delete...');
            }
          }

          if (videoDetails && videoDetails.attachments) {
            try {
              Object.values(videoDetails.attachments).map(async (att) => {
                const attachmentRef = ref(storage, `website/levels/${lid}/attachments/${vid}/${att.name}`);
                await deleteObject(attachmentRef);
              })
            } catch (error) {
              console.log("error deleting attachments folder from storage", error);
              setStatusMessage('error deleting attachments folder from storage');
            }
          }
        })
      }

      // Step 3: Reordering levels
      setStatusMessage("Reordering levels..")

      //// (A): Get an updated videos data version
      const data = await getData(`website/levels/`);

      //// (B): Remove the deleted video
      const newData = Object.keys(data).filter(key => key !== lid).reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

      //// (C): Convert data to array of entries and sort by 'order'
      const sortedData = Object.entries(newData).sort(([, a], [, b]) => a.order - b.order);

      //// (D): Update order values sequentially
      let readyData = {}
      sortedData.forEach(([key, value], index) => {
        const updatedValue = { ...value, order: index + 1 }; // Update order starting from 1
        readyData = { ...readyData, [key]: updatedValue }; // Update the original sortedData
      });

      // Step 4: Updating realtime database
      setStatusMessage("Updating realtime database..")
      await putData(`website/levels`, readyData)
      await deleteData(`levels/${lid}`);

    } catch (error) {
      console.error('Error deleting the level:', error);
      throw new Error('Error deleting the level. Please try again.');
    }
  };

  useEffect(() => {
    if (courseLevelContainerRef.current) {
      const numberLine = courseLevelContainerRef.current.querySelector(".NumberLine");
      const numberSquare = courseLevelContainerRef.current.querySelector(".NumberSquare");
      const containerHeight = courseLevelContainerRef.current.offsetHeight;

      // Get the computed width of NumberSquare
      const numberSquareWidth = parseFloat(window.getComputedStyle(numberSquare).width);

      // Set the height of the NumberLine dynamically based on the width of NumberSquare
      numberLine.style.height = `${containerHeight - numberSquareWidth - 20}px`;
    }
  }, [loading, imageLoaded]);

  // Function to handle the image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination || result.destination.index === result.source.index) return;

    const items = Object.entries(videosDetails).sort((a, b) => a[1].order - b[1].order);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newVideos = Object.fromEntries(items.map((video, index) => {
      video[1].order = index + 1;
      return video;
    }));

    setVideosDetails(newVideos);
    // if (!window.confirm('Are you sure you want to reorder this video?')) return;
    await putData(`levels/${lid}/videos`, videosDetails);
  };

  if (loading) return <Loading />

  if (!levelDetails && !loading)
    return <div>No Data Found</div>;

  return (
    <div className="LevelPage">
      <div className="CourseLevelContainer" ref={courseLevelContainerRef}>
        <div className='LevelNumberContainer'>
          <div className='NumberSquare'>{levelDetails.order}</div>
          <div className='NumberLine'></div>
          <div className="NumberLineCircle"></div>
        </div>
        <div className='CourseLevelContent'>
          <div className='CourseLevelTitleOff'>{levelDetails.title}</div>
          <div className='CourseLevelBody'>{levelDetails.body}</div>
          <div className="ThumbnailLockContainerOff">
            <img
              onLoad={handleImageLoad}
              className='CourseLevelThumbnailOff'
              src={levelDetails.thumbnail}
              alt={levelDetails.thumbnail}
            />
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="LevelVideos">
              {videosDetails ? (
                Object.entries(videosDetails)
                  .sort((a, b) => a[1].order - b[1].order)
                  .map(([vid, video], index) => (
                    <VideoCard key={vid} vid={vid} index={index} video={video} navigate={navigate} />
                  ))
              ) : (
                <div className='AttNotFound'> No videos Found</div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='WelcomeBtns MarginTop'>
        <Link to={`./editLevel`}><button>Edit Level</button></Link>
        <Link to={`./addVideo`}><button className='DeleteBtn'>Add Video</button></Link>
      </div>
      <div className='WelcomeBtns MarginTop'>
        <button onClick={handleDelete} disabled={statusMessage !== ''}>Delete level</button>
        {statusMessage && <div style={{ color: "white" }}>{statusMessage}</div>}
      </div>
    </div>
  );
}

export default LevelPage;
