import React, { useEffect, useState } from 'react';
import { getData, deleteAllUsers } from '../../Fetch';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import "./UsersList.css"
import AddBtn from "../../Assets/AddBtn.svg"
import Loading from '../../Components/Loading';

const TraineesList = () => {
	const [trainees, setTrainees] = useState(null);
	const [filteredTrainees, setFilteredTrainees] = useState(null);
	const [myData, setMyData] = useState('');
	const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
	const [authPassword, setAuthPassword] = useState('');
	const [authError, setAuthError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const traineesData = await getData('users');

				if (traineesData != null) { // Add null check
					const admin = await getData(`admins/${localStorage.getItem('aid')}/`);
					setMyData(admin);

					const traineesArray = Object.entries(traineesData).map(([id, data]) => ({
						id,
						...data,
					}));
					setTrainees(traineesArray);
					setFilteredTrainees(traineesArray);
					setLoading(false)
				} else {
					console.log('No trainees found');
					setTrainees([]);
					setFilteredTrainees([]);
					setLoading(false)
				}
			} catch (error) {
				console.error('Error fetching trainees:', error);
				setLoading(false)
			}
		};

		fetchData();
	}, []);


	const handleDeleteAll = async () => {
		setIsDeleteAllModalOpen(true);
	};

	const handleConfirmDeleteAll = async () => {
		if (authPassword !== myData.password) {
			setAuthError('Enter the correct password!');
			return;
		}

		try {

			setLoading(true)

			const user = getAuth().currentUser;

			if (!user) {
				// Handle the case when the user is not authenticated
				console.log('User not authenticated. Please log in.');
				setLoading(false)
				return;
			}

			// Call the Cloud Function to delete all users
			const response = await deleteAllUsers();

			if (response.success) {
				alert('All users deleted successfully!');
				// Refresh the users list
				const refreshedUsersData = [];

				// Add null check for refreshedUsersData
				if (refreshedUsersData != null) {
					const refreshedUsersArray = Object.entries(refreshedUsersData).map(([id, data]) => ({
						id,
						...data,
					}));
					setTrainees(refreshedUsersArray);
					setFilteredTrainees(refreshedUsersArray);
					setIsDeleteAllModalOpen(false);
					setAuthError('');
					setAuthPassword('');
					setLoading(false)
				} else {
					console.error('Error fetching refreshed trainees data:', refreshedUsersData);
					setAuthError('Error fetching refreshed trainees data');
					setAuthPassword('');
					setLoading(false)
				}
			} else {
				setAuthError(response.message || 'Error deleting all users.');
				setAuthPassword('');
				setLoading(false)
			}
		} catch (error) {
			console.error('Error authenticating admin or deleting users:', error);
			setAuthError('Something went wrong, refresh the page and try again');
			setAuthPassword('');
			setLoading(false)
		}
	};

	const handleCancelDeleteAll = () => {
		setIsDeleteAllModalOpen(false);
		setAuthPassword('');
		setAuthError(null);
	};

	const handleSearch = (query) => {
		const lowerCaseQuery = query.toLowerCase();
		const filtered = trainees.filter(
			(trainee) =>
				trainee.name.toLowerCase().includes(lowerCaseQuery) ||
				trainee.id.toLowerCase().includes(lowerCaseQuery)
		);
		setFilteredTrainees(filtered);
	};

	if(loading) return <Loading/>

	return (
		<div>
			<div className='SectionTitle MarginTop'>Trainees List</div>
			<div className='SearchContainer'>
				<input
					className='ListSearch'
					type="text"
					id="searchQuery"
					onChange={(e) => handleSearch(e.target.value)}
					placeholder="Type to search..."
				/>
				<Link to={'/trainees/add'}>
					<img className='AddBtn' src={AddBtn} alt="AddBtn" />
				</Link>
			</div>
			{filteredTrainees ? (
				filteredTrainees.length > 0 ? (
					<div className='UsersListContainer'>
						{filteredTrainees.map((trainee) => (
							<Link className='UsersListItem' key={trainee.id} to={`/trainees/${trainee.id}`}>
								<div>{trainee.name}</div>
								<div>#{trainee.id}</div>
							</Link>
						))}
					</div>
				) : (
					<p>No matching trainees found.</p>
				)
			) : (
				<p>Loading trainees...</p>
			)}

			{myData.role === 'super' && (
				<div className='FlexColumn MarginTop'>
					<button className='AppBtn DeleteBtn' onClick={handleDeleteAll} disabled={!trainees || trainees.length === 0}>
						DELETE ALL
					</button>
				</div>
			)}

			{/* Delete All Modal */}
			{isDeleteAllModalOpen && (
				<form>
					<div className='MarginTop'>
						<input
							className='AuthInput'
							placeholder='Enter Password:'
							type="password"
							id="authPassword"
							value={authPassword}
							onChange={(e) => setAuthPassword(e.target.value)}
							required
						/>
						{authError && <p style={{ color: 'red' }}>{authError}</p>}
						<div className="WelcomeBtns MarginAuto MarginTop">
						<button type="button" onClick={handleCancelDeleteAll}>Cancel</button>
						<button className='DeleteBtn' type="button" onClick={handleConfirmDeleteAll}>Confirm</button>
						</div>
					</div>
				</form>
			)}
		</div>
	);
};

export default TraineesList;
