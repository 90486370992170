import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getData, deleteData, postData, putData } from '../../Fetch';
import { getStorage, ref, deleteObject, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import "./VideoPage.css"
import File from "../../Assets/file.svg"
import Next from "../../Assets/NextBtn.svg"
import Prev from "../../Assets/PrevBtn.svg"
import Loading from '../../Components/Loading';
import axios from 'axios';

const Token = 'dd5a525f6c393f9d177696acd54d5dd4';

function VideoPage() {
  const navigate = useNavigate();
  const { lid, vid } = useParams();
  const [videoDetails, setVideoDetails] = useState(null);
  const [videosList, setVideosList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    const fetchVideoDetails = async () => {
      try {
        const data = await getData(`levels/${lid}/videos/`);
        const videoDetails = data[vid];
        if (videoDetails) {
          setVideoDetails(videoDetails)
          const videosData = await getData(`levels/${lid}/videos`);
          setVideosList(Object.entries(videosData).map(([key, video]) => ({
            ...video, // Spread existing properties
            vid: key
          })).sort((a, b) => a.order - b.order));
          setLoading(false);
        } else {
          alert(`Level with id ${vid} not found.`);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching level details:', error);
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [lid, vid]);

  const currentIndex = videosList.findIndex((video) => video.vid === vid);

  const navigateToPrevious = () => {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      const previousVideoId = videosList[previousIndex].vid;
      if (previousVideoId) {
        setLoading(true)
        navigate(`/website/lvl/${lid}/vid/${previousVideoId}`);
      }
      else {
        alert("Error, something went wrong")
      }
    }
  };

  const navigateToNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < videosList.length) {
      const nextVideoId = videosList[nextIndex].vid;
      if (nextVideoId) {
        setLoading(true)
        navigate(`/website/lvl/${lid}/vid/${nextVideoId}`);
      }
      else {
        alert("Error, something went wrong")
      }
    }
  };

  const handleDelete = async () => {
    // Confirm deletion
    const confirmDelete = window.confirm('Are you sure you want to delete this video?');

    if (confirmDelete) {
      setLoading(true);
      try {
        const result = await deleteVideoDetails(videoDetails);
        alert(result);
        setLoading(false);
        navigate(-1);
      } catch (error) {
        alert(error);
        setLoading(false);
      }
    }
  };

  const deleteVideoDetails = async (videoDetails) => {
    try {
      // Delete video from vimeo
      if (videoDetails?.id) {
        try {
          await axios.delete(
            `https://api.vimeo.com/videos/${videoDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
              }
            }
          );
        } catch (deleteError) {
          console.error('Error deleting old video:', deleteError);
        }
      }

      // Get an updated videos data version
      const data = await getData(`levels/${lid}/videos/`);

      // Remove the deleted video
      const newData = Object.keys(data).filter(key => key !== vid).reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

      // Reorder the videos
      //// Step 1: Convert data to array of entries and sort by 'order'
      const sortedData = Object.entries(newData).sort(([, a], [, b]) => a.order - b.order);
      
      //// Step 2: Update order values sequentially
      let readyData = {}
      sortedData.forEach(([key, value], index) => {
        const updatedValue = { ...value, order: index + 1 }; // Update order starting from 1
        readyData = {...readyData, [key]: updatedValue}; // Update the original sortedData
      });

      // Update firebase realtime database
      await putData(`levels/${lid}/videos`, readyData)

      return 'Video deleted successfully!';
    } catch (error) {
      console.error('Error deleting the video:', error);
      throw new Error('Error deleting the video. Please try again.');
    }
  };

  const deleteAttachment = async (attId) => {
    const confirm = window.confirm("Are you sure you want to delete the attachment?")
    if (!confirm) return
    setLoading(true);
    try {
      // Create a storage reference
      const storage = getStorage();

      // Delete the attachment if it exists
      if (videoDetails.attachments && videoDetails.attachments[attId].name) {
        try {
          const attachmentRef = ref(storage, `website/levels/${lid}/attachments/${vid}/${videoDetails.attachments[attId].name}`);
          await deleteObject(attachmentRef);
        } catch (error) {
          console.log("error deleting attachment", error);
        }
        try {
          await deleteData(`levels/${lid}/videos/${vid}/attachments/${attId}`);
        } catch (error) {
          console.log("error updating database ", error);
        }
       
        setVideoDetails((prevVideoDetails) => {
          const updatedAttachments = { ...prevVideoDetails.attachments };
          delete updatedAttachments[attId];
          return { ...prevVideoDetails, attachments: updatedAttachments };
        });
        setLoading(false);
        alert('Attachment deleted successfully!');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error deleting the attachment:', error);
      alert('Error deleting the attachment. Please try again.');
      setLoading(false);
    }
  };

  const uploadFile = async (file) => {
    try {
      const fileName = `file-${Date.now()}`;
      const storage = getStorage();
      const storageRef = ref(storage, `website/levels/${lid}/attachments/${vid}/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress)
        },
        (error) => {
          console.error('Error during file upload:', error);
          alert('Error during file upload. Please try again.');
        },
        async () => {
          const fileURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Update the Realtime Database with the new attachment URL
          const attachmentData = { name: fileName, file: fileURL, display: file.name };
          await postData(`levels/${lid}/videos/${vid}/attachments`, attachmentData);

          // Update the state with the new attachment
          setVideoDetails((prevVideoDetails) => {
            const updatedAttachments = { ...prevVideoDetails.attachments, [Date.now()]: attachmentData };
            return { ...prevVideoDetails, attachments: updatedAttachments };
          });

          setUploadProgress(0)
          alert("File is uploaded successfully");
          return 'File uploaded successfully!';
        }
      );

    } catch (error) {
      setUploadProgress(0)
      console.log(error)
      throw new Error('An error occurs, refresh the page and try again.');
    }
  }

  const handleUploadFile = (event) => {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.click();

      fileInput.addEventListener('change', async (event) => {
        const file = event.target.files[0];

        if (file) {
          try {
            await uploadFile(file);
          } catch (error) {
            alert("an error occurs:", error)
          }
        }
      });
    } catch (error) {
      console.error('Error handling welcome video update:', error);
      alert('Error handling welcome video update. Please try again.');
    }
  };

  if (loading) return <Loading />

  if (!videoDetails && !loading)
    return <div>No Data Found</div>;


  return (
    <div className="VideoPage">
      <div className="VideoPageTitle">
        <div className="VideoPageTitleOrder">{videoDetails.order}</div>
        <div className="VideoPageTitleText">{videoDetails.title}</div>
      </div>
      <div className='VideoPagePlayer'>
        <iframe
          src={videoDetails.video}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="welcome-video"
          style={{ width: '100%', height: '100%', display: 'block', border: 'none', borderRadius: 'min(15px, calc(100vw * (7 / 950)))' }}
        ></iframe>
      </div>
      <div className="VideoPageBody">{videoDetails.body}</div>
      <div className="VideoPageAttachments FlexColumn">
        {videoDetails.attachments ?
          Object.entries(videoDetails.attachments).map(([attId, att]) => (
            <div className="AttachmentFile" key={attId}>
              <div className='AttachmentFileLink'>
                <Link to={att.file}> <img src={File} alt={att.display} /> </Link>
                <Link to={att.file}>{att.display}</Link>
              </div>
              <button className='AppBtn DeleteBtn' onClick={() => deleteAttachment(attId)}>delete file</button>
            </div>
          ))
          :
          <div className="AttNotFound">No attachments found</div>
        }
        <div className='FlexColumn MarginAuto'>
          <button className='AppBtn MarginTop' onClick={handleUploadFile}>Upload File</button>
          <div>{uploadProgress > 0 && `Uploading ${uploadProgress.toFixed(0)}%`}</div>
        </div>
      </div>
      <div className="VideoBtnContainer">
        <div className="VideoBtn">
          <img className={currentIndex === 0 ? "BtnDisabled" : ""} src={Prev} alt="Prev" onClick={navigateToPrevious} />
          <img className={currentIndex === Object.values(videosList).length - 1 ? "BtnDisabled" : ""} src={Next} alt="Next" onClick={navigateToNext} />
        </div>
      </div>
      <div className='WelcomeBtns MarginTop'>
        <button onClick={handleDelete}>Delete video</button>
        <Link to={`./editVideo`}><button className='DeleteBtn'>Edit Video</button></Link>
      </div>
    </div>
  );
}

export default VideoPage;
