import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getData, deleteAdmin } from '../../Fetch';
import Loading from '../../Components/Loading';

function AdminProfile() {
  const { aid } = useParams();
  const navigate = useNavigate();

  const [adminData, setAdminData] = useState(null);
  const [myRole, setMyRole] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminData = await getData(`admins/${aid}`);
        const role = await getData(`admins/${localStorage.getItem('aid')}/role`);

        if (adminData) {
          setAdminData(adminData);
          setMyRole(role)
          setLoading(false)
        } else {
          console.log('Admin not found');
          setLoading(false)
          navigate('/admins');
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setLoading(false)
      }
    };

    fetchAdminData();
  }, [aid, navigate]);

  const handleDelete = async () => {
    setLoading(true)
    const canDelete = shouldAllowDelete();
    if (canDelete) {
      const confirmed = window.confirm('Are you sure you want to delete this admin?');

      if (confirmed) {
        try {
          // Delete admin from the database
          const response = await deleteAdmin(aid);

          if (response && response.success) {
            alert('Admin deleted successfully!');
            setLoading(false)
            navigate('/admins');
          } else {
            alert('Error deleting admin. Please try again.');
            setLoading(false)
          }
        } catch (error) {
          console.error('Error deleting admin:', error);
          alert('Error deleting admin. Please try again.');
          setLoading(false)
        }
      }
      setLoading(false)
    } else {
      alert('You do not have permission to delete this admin.');
      setLoading(false)
    }
  };


  const shouldAllowDelete = () => {
    // Check conditions for enabling the delete button
    return myRole === 'super' && adminData.role !== "super"
  };

  if(loading) return <Loading />

  return (
    <div>
      <h1>Admin Profile</h1>
      {adminData ? (
        <div className='UserFieldsContainer'>
          <div className='UserField'>
            <label htmlFor="Name">Name:</label>
            <div>{adminData.name}</div>
          </div>
          <div className='UserField'>
            <label htmlFor="Email">Email:</label>
            <div>{adminData.email}</div>
          </div>
          <div className='UserField'>
            <label htmlFor="Email">Password:</label>
            <div>{adminData.password}</div>
          </div>
          <div className='UserField'>
            <label htmlFor="Role">Role: </label>
            <div>{adminData.role}</div>
          </div>
          <div className='FlexColumn MarginTop'>
            {shouldAllowDelete() &&
              <button className='AppBtn DeleteBtn' type="button" onClick={handleDelete} disabled={!shouldAllowDelete()}>
                Delete Admin
              </button>
            }
          </div>
        </div>
      ) : (
        <p>Loading admin data...</p>
      )}
    </div>
  );
}

export default AdminProfile;
