import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAdmin } from '../../Fetch';
import Loading from '../../Components/Loading';
import { getAuth } from 'firebase/auth';

function AddAdmin() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    // Validate form whenever any input field changes
    validateForm();
  }, [name, email, password]);

  useEffect(() => {
    // Listen for the beforeunload event to show a confirmation message
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const validateEmail = (email) => {
    // Placeholder for your preferred email validation logic
    // e.g., using a regular expression or external library
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      if (!isFormValid) {
        alert('Please fill in all required fields (Name, Email, Password) before saving.');
        setLoading(false)
        return;
      }

      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
        setLoading(false)
        return;
      }
  
      if (password.length < 8) {
        alert('Password must be at least 8 characters long.');
        setLoading(false)
        return;
      }

      // Check if admin is authenticated
      const auth = getAuth();
      const adminUser = auth.currentUser;

      if (!adminUser) {
        // Admin not authenticated, handle accordingly
        alert('Admin not authenticated. Please log in again.');
        setLoading(false)
        return;
      }

      // Create the admin
      const response = await createAdmin(email, password, name);

      if (response && response.success) {
        try {
          alert('Admin added successfully!');
          navigate('/admins');
          setLoading(false)
        } catch (error) {
          console.error('Error adding admin:', error);
          alert('Error adding admin. Please try again.');
          setLoading(false)
        }
      } else {
        console.log('Error adding admin');
        alert('Error adding admin. Please try again.');
        setLoading(false)
      }
    } catch (error) {
      console.error('Error adding admin:', error);
      alert('Error adding admin. Please try again.');
      setLoading(false)
    }
  };

  const validateForm = () => {
    // Check if all required fields are filled
    setIsFormValid(name.trim() !== '' && email.trim() !== '' && password.trim() !== '');
  };

  const handleCancel = () => {
    // Show a confirmation message only if there are unsaved changes
    if (isFormDirty) {
			const discardChanges = window.confirm('Discard changes and go back?');

			if (discardChanges) {
				navigate("/admins");
			}
		} else {
			navigate("/admins");
		}
  };

  if(loading) return <Loading />

  return (
    <div>
      <div className="SectionTitle">Add Admin</div>
      <form onSubmit={handleSave} className="UserFieldsContainer">
        <div className="UserField">
          <label htmlFor="Name">Name:</label>
          <input type="text" name="Name" value={name} onChange={(e) => { setName(e.target.value); setIsFormDirty(true); }} required />
        </div>
        <div className="UserField">
          <label htmlFor="Email">Email:</label>
          <input placeHolder="example@gmail.com" type="email" name="Email" value={email} onChange={(e) => { setEmail(e.target.value); setIsFormDirty(true); }} required />
        </div>
        <div className="UserField">
          <label htmlFor="Password">Password:</label>
          <input minLength="8" placeHolder="+8 Digits" type="password" name="Password" value={password} onChange={(e) => { setPassword(e.target.value); setIsFormDirty(true); }} required />
        </div>
        <div className="WelcomeBtns MarginAuto MarginTop">
        <button type="button" onClick={handleCancel}>Cancel</button>
        <button className="DeleteBtn" type="submit">Save</button>
        </div>
      </form>
    </div>
  );
}

export default AddAdmin;
