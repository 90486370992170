import axios from "axios";
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get, push, set, remove } from "firebase/database";

const cloudUrl = "https://us-central1-gc-edu.cloudfunctions.net/api";
// const cloudUrl = "http://127.0.0.1:5001/gc-edu/us-central1/api";

const createAdmin = async (email, password, name) => {
  try {
    // Get the current user
    const user = getAuth().currentUser;

    if (!user) {
      // Handle the case when the user is not authenticated
      console.log('User not authenticated. Please log in.');
      return;
    }

    // Send the request to the Cloud Function 
    const response = await axios.post(`${cloudUrl}/createAdmin`, {
      email: email,
      password: password,
      name: name,
      aid: localStorage.getItem("aid"),
    });

    console.log(response.data);
    return response.data;
    // Handle success or error response from the Cloud Function
  } catch (error) {
    console.error('Error invoking Cloud Function:', error);
    // Handle error
  }
};

const deleteSecondaryAdmins = async () => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteSecondaryAdmins`, {
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const vimeoApi = async (videoId) => {
  try {
    const response = await axios.post(`${cloudUrl}/vimeoApi`, {
      aid: localStorage.getItem("aid"),
      videoId
    });

    console.log(response.data.message);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const processVideo = async (path, videoName) => {
  try {
    const response = await axios.post(`${cloudUrl}/processVideo`, {
      aid: localStorage.getItem("aid"),
      path,
      videoName
    });

    console.log(response.data.message);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const testFunction = async () => {
  try {
    const response = await axios.post(`${cloudUrl}/testFunction`, {
      aid: localStorage.getItem("aid"),
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteAdmin = async (did) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteAdmin`, {
      aid: localStorage.getItem("aid"),
      did
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createUser = async (name, phone, level) => {
  try {
    // Get the current user
    const user = getAuth().currentUser;

    if (!user) {
      // Handle the case when the user is not authenticated
      console.log('User not authenticated. Please log in.');
      return;
    }

    // Send the request to the Cloud Function 
    const response = await axios.post(`${cloudUrl}/createUser`, {
      name,
      phone,
      level,
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
    // Handle success or error response from the Cloud Function
  } catch (error) {
    console.error('Error invoking Cloud Function:', error);
    // Handle error
  }
};

const deleteAllUsers = async () => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteAllUsers`, {
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (uid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteUser`, {
      aid: localStorage.getItem("aid"),
      uid
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteLevel = async (lid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteLevel`, { aid: localStorage.getItem("aid"), lid });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteVideo = async (lid, vid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteVideo`, { aid: localStorage.getItem("aid"), lid, vid });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//******************************************************************************** */

// Function for making a GET request
const getData = async (endpoint) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    // Get the current authenticated user
    const user = auth.currentUser;
    const dataRef = ref(database, `Api/${endpoint}`);
    if (user) {
      const authToken = await user.getIdToken();
      let snapshot = await get(dataRef, { auth: authToken });
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    } else {
      let snapshot = await get(dataRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a POST request
const postData = async (endpoint, data) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const dataRef = ref(database, `Api/${endpoint}`);
    const authToken = user ? await user.getIdToken() : null;

    if (authToken) {
      // You may need to adjust this logic based on your data structure
      const newItemRef = push(dataRef);
      await set(newItemRef, data, authToken);
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a PUT request
const putData = async (endpoint, data) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const dataRef = ref(database, `Api/${endpoint}`);
    const authToken = user ? await user.getIdToken() : null;

    if (authToken) {
      // You may need to adjust this logic based on your data structure
      await set(dataRef, data, authToken);
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a DELETE request
const deleteData = async (endpoint) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const dataRef = ref(database, `Api/${endpoint}`);
    const authToken = user ? await user.getIdToken() : null;

    if (authToken) {
      // You may need to adjust this logic based on your data structure
      await remove(dataRef, authToken);
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    throw error;
  }
};

export {
  getData, postData, putData, deleteData,
  createAdmin, deleteSecondaryAdmins, deleteAdmin,
  deleteAllUsers, deleteUser, createUser,
  deleteLevel, deleteVideo, processVideo, testFunction, vimeoApi
};
