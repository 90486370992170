import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getData, createUser } from '../../Fetch';
import { getAuth } from 'firebase/auth';
import Loading from '../../Components/Loading';

function AddTrainee() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(true);
  const [levelsNo, setLevelsNo] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    // Validate form whenever any input field changes
    validateForm();
  }, [name, phone, level]);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const levels = await getData('website/levels');
        if (levels) {
          setLevelsNo(Object.keys(levels).length);
          setLoading(false)
        } else {
          console.log('Levels not available');
          alert('Access Denied');
          navigate('/trainees');
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching levels:', error); 
        setLoading(false)
      }
    };

    fetchLevels();
  }, [navigate]);

  useEffect(() => {
    // Listen for the beforeunload event to show a confirmation message
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true)
    try {
      if (!isFormValid) {
        alert('Please fill in all required fields (Name, Phone, Level) before saving.');
        return;
      }

      // Check if admin is authenticated
      const auth = getAuth();
      const adminUser = auth.currentUser;

      if (!adminUser) {
        // Admin not authenticated, handle accordingly
        alert('Admin not authenticated. Please log in again.');
        return;
      }

      // Create the user
      const response = await createUser(name, phone, level);

      if (response && response.success) {
        try {
          setLoading(false)
          alert('User added successfully!');
          navigate('/trainees');
        } catch (error) {
          console.error('Error adding trainee', error);
          setLoading(false)
          alert('Error adding trainee. Please try again.');
        }
      } else {
        console.log('Error adding trainee', response);
        setLoading(false)
        alert('Error adding trainee. Please try again.');
      }
    } catch (error) {
      console.error('Error adding trainee:', error);
      setLoading(false)
      alert('Error adding trainee. Please try again.');
    }
  };

  const validateForm = () => {
    // Check if all required fields are filled
    setIsFormValid(name.trim() !== '' && phone.trim() !== '' && level.trim() !== '');
    setIsFormDirty(true);
  };

  const handleCancel = () => {
    // Show a confirmation message only if there are unsaved changes
    if (isFormDirty) {
			const discardChanges = window.confirm('Discard changes and go back?');

			if (discardChanges) {
				navigate("/trainees");
			}
		} else {
			navigate("/trainees");
		}
  };

  if(loading) return <Loading />

  return (
    <div>
      <div className='SectionTitle'>Add Trainee</div>
      <form onSubmit={handleSave} className="UserFieldsContainer">
        <div className="UserField">
          <label htmlFor="Name">Name:</label>
          <input type="text" name="Name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="UserField">
          <label htmlFor="Phone">Phone:</label>
          <input type="text" name="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <div className="UserField">
          <label htmlFor="Level">Level:</label>
          <select name="Level" value={level} onChange={(e) => setLevel(e.target.value)} required>
            <option value="">Select Level</option>
            {Array.from({ length: levelsNo }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="WelcomeBtns MarginAuto MarginTop">
          <button type="button" onClick={handleCancel}>Cancel</button>
          <button className="DeleteBtn" type="submit">Save</button>
        </div>
      </form>
    </div>
  );
}

export default AddTrainee;
