import React, { useEffect, useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { postData } from '../../Fetch';
import { useNavigate } from 'react-router-dom';
import "./AddPage.css"


function AddNewsPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Preview the image locally
    const reader = new FileReader();
    reader.onloadend = () => {
      // Handle the image preview, e.g., set it as a state variable
      // For simplicity, assuming you have a setImagePreview function
      // setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    // Confirm save
    const confirmSave = window.confirm('Are you sure you want to save this news?');

    if (confirmSave) {
      try {
        if (!title || !image) {
          alert('Please provide a title and upload an image.');
          return;
        }

        // Generate a unique file name for storage
        const fileName = `news-${Date.now()}`;

        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(storage, `website/news/${fileName}`);

        // Create a new upload task with progress updates
        const uploadTask = uploadBytesResumable(storageRef, image);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error during upload:', error);
            alert('Error during upload. Please try again.');
          },
          async () => {
            try {
              // Upload completed successfully, get the download URL
              const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
              const timestamp = Date.now();

              // Save news data to the Realtime Database
              const newsData = {
                name: fileName,
                image: imageURL,
                title: title,
                timestamp: timestamp
              };

              await postData('website/news', newsData);

              alert('News saved successfully!');
              navigate(-1)
            } catch (uploadError) {
              console.error('Error saving news:', uploadError);
              alert('Error saving news. Please try again.');
              navigate(-1)
            }
          }
        );
      } catch (error) {
        console.error('An error occurred:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const handleCancel = () => {
    // Confirm discard changes
    const confirmDiscard = window.confirm('Discard changes and go back?');

    if (confirmDiscard) {
      navigate("/website")

    }
  };

  return (
    <div>
      <h1>Add News</h1>
      <label>
        <input placeholder='Post Title' className='SectionTitle inputStyle' type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      </label>
      <div>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>
      {image && (
        <div>
          <img className='ImgContent' src={URL.createObjectURL(image)} alt="News Preview" />
        </div>
      )}
      <div className="WelcomeBtns MarginAuto MarginTop">
        <button onClick={handleCancel}>Cancel</button>
        <button className='DeleteBtn' onClick={handleSave}>Save</button>
      </div>
      <div>{uploadProgress > 0 && `Uploading ${uploadProgress.toFixed(0)}%`}</div>
    </div>
  );
}

export default AddNewsPage;