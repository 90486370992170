import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getData, putData } from '../../Fetch';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Components/Loading';

function EditLevelPage() {
	const navigate = useNavigate();
	const { lid } = useParams();

	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState('');
	const [statusMessage, setStatusMessage] = useState('');
	const [levelData, setLevelData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchLevelData = async () => {
			try {
				const levelData = await getData(`website/levels/${lid}`);

				if (levelData) {
					setTitle(levelData.title || '');
					setBody(levelData.body || '');
					setImagePreview(levelData.thumbnail || '');
					setImage(levelData.thumbnail || '');
					setLevelData(levelData)
					setLoading(false);
				} else {
					alert(`Level with id ${lid} not found.`);
					setLoading(false);
					navigate(-1);
				}
			} catch (error) {
				console.error('Error fetching level data:', error);
				setLoading(false);
			}
		};
		fetchLevelData();
	}, [lid, navigate]);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		setImage(file);

		// Preview the image locally
		const reader = new FileReader();
		reader.onloadend = () => {
			setImagePreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleSave = async (e) => {
		e.preventDefault();

		// Confirm save
		const confirmSave = window.confirm('Are you sure you want to save changes to this level?');

		if (confirmSave) {
			try {
				if (!title) {
					alert('Please provide a title.');
					return;
				}

				// If image is changed, upload the new image
				if (image !== levelData.thumbnail) {
					// Step 1: Uploading the new thumbnail
					setStatusMessage("Uploading the new thumbnail")

					const fileName = `level-${Date.now()}`;
					const storage = getStorage();
					const storageRef = ref(storage, `website/thumbnails/${fileName}`);

					const uploadTask = uploadBytesResumable(storageRef, image);

					uploadTask.on(
						'state_changed',
						(snapshot) => {
							const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							setStatusMessage(`Uploading thumbnail: ${progress.toFixed(0)}%`);
						},
						(error) => {
							console.error('Error during upload:', error);
							alert('Error during upload. Please try again.');
						},
						async () => {
							try {
								const imageURL = await getDownloadURL(uploadTask.snapshot.ref);

								// Update level data in the Realtime Database
								const updatedLevelData = {
									...levelData,
									name: fileName,
									thumbnail: imageURL,
									title: title,
									body: body
								};

								// Step 2: Deleting the old thumbnail
								setStatusMessage("Deleting the old thumbnail")

								if (levelData.name) {
									const oldRef = ref(storage, `website/thumbnails/${levelData.name}`);
									try {
										await deleteObject(oldRef);
									} catch (deleteError) {
										console.error('Error deleting old image:', deleteError);
										alert('Error deleting old image. The new image has been uploaded.');
									}
								}

								await putData(`website/levels/${lid}`, updatedLevelData);

								alert('Changes saved successfully!');
								navigate(-1);
							} catch (uploadError) {
								console.error('Error updating level data:', uploadError);
								alert('Error updating level data. Please try again.');
							}
						}
					);
				} else {
					// Update level data in the Realtime Database without changing the thumbnail
					const updatedLevelData = {
						...levelData,
						title: title,
						body: body
					};

					await putData(`website/levels/${lid}`, updatedLevelData);

					alert('Changes saved successfully!');
					navigate(-1);
				}
			} catch (error) {
				console.error('An error occurred:', error);
				alert('An error occurred. Please try again.');
				navigate(-1);
			}
		}
	};

	const handleCancel = () => {
		// Confirm discard changes
		const confirmDiscard = window.confirm('Discard changes and go back?');

		if (confirmDiscard) {
			navigate(`/website/lvl/${lid}`)
		}
	};

	if (loading) return <Loading />

	if (!levelData && !loading)
		return <div>No Data Found</div>;

	return (
		<form onSubmit={handleSave}>
			<h1>Edit Level</h1>
			<label>
				<input placeholder='Level Title' className='SectionTitle inputStyle' required type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
			</label>
			<br />
			<label>
				<textarea placeholder='Level Description' className='CourseLevelBody AreaStyle' type="text" value={body} onChange={(e) => setBody(e.target.value)} />
			</label>
			<br />
			<label>
				<input required={!imagePreview} type="file" accept="image/*" onChange={handleImageChange} />
			</label>
			<br />
			{image && (
				<div>
					{/* Display the image preview */}
					{imagePreview && <img className='Content' src={imagePreview} alt="Level Preview" />}
				</div>
			)}
			<br />
			<div className="WelcomeBtns MarginAuto MarginTop">
				<button type="button" onClick={handleCancel} disabled={statusMessage !== ''}>Cancel</button>
				<button className='DeleteBtn' type="submit" disabled={statusMessage !== ''}>Save Changes</button>
			</div>
			<div>{statusMessage}</div>
		</form>
	);
}

export default EditLevelPage;
