import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getData, putData } from '../../Fetch';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Components/Loading';
import axios from 'axios';

const Token = 'dd5a525f6c393f9d177696acd54d5dd4';

function EditLevelPage() {
	const navigate = useNavigate();
	const { lid, vid } = useParams();

	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [video, setVideo] = useState(null);
	const [videoPreview, setVideoPreview] = useState();
	const [statusMessage, setStatusMessage] = useState('');
	const [videoData, setVideoData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchLevelData = async () => {
			try {
				const videoData = await getData(`levels/${lid}/videos/${vid}`);

				if (videoData) {
					setTitle(videoData.title || '');
					setBody(videoData.body || '');
					setVideo(videoData.video || '');
					setVideoData(videoData)
					setLoading(false);
				} else {
					alert(`Level or video not found.`);
					setLoading(false);
					navigate(-1);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				setLoading(false);
			}
		};
		fetchLevelData();
	}, [lid, vid, navigate]);

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideo(file);

		// Preview the video locally
		const reader = new FileReader();
		reader.onloadend = () => {
			setVideoPreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleSave = async (e) => {
		e.preventDefault();

		// Confirm save
		const confirmSave = window.confirm('Are you sure you want to save changes to this video?');

		if (confirmSave) {
			try {
				if (!title) {
					alert('Please provide a title.');
					return;
				}
				// If video is changed, upload the new video
				if (video !== videoData.video) {
					try {
						// Step 1: Delete the old video from Vimeo
						if (videoData?.id) {
							setStatusMessage('Deleting the old video...');
							try {
								await axios.delete(
									`https://api.vimeo.com/videos/${videoData.id}`,
									{
										headers: {
											Authorization: `Bearer ${Token}`,
										}
									}
								);
							} catch (deleteError) {
								console.error('Error deleting old video:', deleteError);
								setStatusMessage('Failed to delete old video, continuing with upload...');
							}
						}

						// Step 2: Get upload link from Vimeo
						setStatusMessage('Getting upload link from Vimeo...');
						const uploadLinkResponse = await axios.post(
							'https://api.vimeo.com/me/videos',
							{
								upload: {
									approach: 'tus',
									size: video.size
								}
							},
							{
								headers: {
									Authorization: `Bearer ${Token}`,
									'Content-Type': 'application/json'
								}
							}
						);

						const uploadLink = uploadLinkResponse.data.upload.upload_link;

						// Step 3: Upload the video file to Vimeo using the upload link with progress tracking
						setStatusMessage('Uploading video...');
						await axios.patch(uploadLink, video, {
							headers: {
								'Content-Type': 'application/offset+octet-stream',
								'Upload-Offset': 0,
								'Tus-Resumable': '1.0.0',
							},
							onUploadProgress: (progressEvent) => {
								const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
								setStatusMessage(`Uploading video: ${percentCompleted}%`);
							}
						});

						const videoUri = uploadLinkResponse.data.uri;
						const videoId = videoUri.split('/videos/').pop();
						const videoLink = `https://player.vimeo.com/video/${videoId}`;

						// Step 4: Set the video name on Vimeo
						setStatusMessage('Setting video name...');
						const videoName = `video-${videoId}`;
						await axios.patch(
							`https://api.vimeo.com${videoUri}`,
							{
								name: videoName
							},
							{
								headers: {
									Authorization: `Bearer ${Token}`,
									'Content-Type': 'application/json'
								}
							}
						);

						// Step 5: Save the video link to Firebase Realtime Database
						setStatusMessage('Saving video link to database...');
						const path = `levels/${lid}/videos/${vid}`;
						const updatedVideoData = {
							...videoData,
							title: title,
							body: body,
							id: videoId,
							video: videoLink
						};

						await putData(`${path}`, updatedVideoData)

						alert('Video is uploaded successfully, please wait for server side optimizations.');
						setStatusMessage(null);

						navigate(-1);
					} catch (uploadError) {
						console.error('Error updating video data:', uploadError);
						alert('Error updating video data. Please try again.');
					}

				} else {
					// Update video data in the Realtime Database without changing the video
					const updatedVideoData = {
						...videoData,
						title: title,
						body: body
					};

					await putData(`levels/${lid}/videos/${vid}`, updatedVideoData);

					alert('Changes saved successfully!');
					setLoading(false);
					navigate(-1);
				}
			} catch (error) {
				console.error('An error occurred:', error);
				alert('An error occurred. Please try again.');
			}
		}
	};

	const handleCancel = () => {
		// Confirm discard changes
		const confirmDiscard = window.confirm('Discard changes and go back?');

		if (confirmDiscard) {
			navigate(`/website/lvl/${lid}/vid/${vid}`)
		}
	};

	if (loading) return <Loading />

	if (!videoData && !loading)
		return <div>No Data Found</div>;

	return (
		<form onSubmit={handleSave}>
			<h1>Edit Video</h1>
			<label>
				<input placeholder='Level Title' className='SectionTitle inputStyle' required type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
			</label>
			<br />
			<label>
				<textarea placeholder='Level Description' className='CourseLevelBody AreaStyle' type="text" value={body} onChange={(e) => setBody(e.target.value)} />
			</label>
			<br />
			<label>
				<input type="file" accept="video/*" onChange={handleVideoChange} />
			</label>
			<br />
			{video && (
				<div>
					{/* Display the video preview */}
					{videoPreview && <video className='Content' src={videoPreview} alt="Video Preview" />}
				</div>
			)}
			<br />
			<div className="WelcomeBtns MarginAuto MarginTop">
				<button type="button" onClick={handleCancel} disabled={statusMessage !== ''}>Cancel</button>
				<button className='DeleteBtn' type="submit" disabled={statusMessage !== ''}>Save Changes</button>
			</div>
			<div>{statusMessage}</div>
		</form>
	);
}

export default EditLevelPage;
