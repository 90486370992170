import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getData, deleteData } from '../../Fetch';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Loading from '../../Components/Loading';

function NewsPage() {
	const navigate = useNavigate()
	const { nid } = useParams();
	const [newsDetails, setNewsDetails] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchNewsDetails = async () => {
			try {
				const data = await getData('website');
				const newsDetails = data.news[nid];

				if (newsDetails) {
					setNewsDetails(newsDetails);
					setLoading(false);
				} else {
					console.error(`News with id ${nid} not found.`);
					setLoading(false);
				}
			} catch (error) {
				console.error('Error fetching news details:', error);
				setLoading(false);
			}
		};

		fetchNewsDetails();
	}, [nid]);

	const handleDelete = async () => {
		// Confirm deletion
		const confirmDelete = window.confirm('Are you sure you want to delete this news?');

		if (confirmDelete) {
			setLoading(true);
			try {
				const result = await deleteNews(newsDetails);
				alert(result);
				setLoading(false);
				navigate(-1);
			} catch (error) {
				setLoading(false);
				alert(error);
			}
		}
	};

	const deleteNews = async (newsDetails) => {
		console.log(newsDetails.name);
		try {
			// Create a storage reference
			const storage = getStorage();

			// Delete the news image if it exists
			if (newsDetails.image) {
				try {
					console.log('Deleting from storage:', `website/news/${newsDetails.name}`);
					const newsImageRef = ref(storage, `website/news/${newsDetails.name}`);

					await deleteObject(newsImageRef);
				}
				catch (error) {
					console.log("error deleting from storage", error)
				}
			}
			// Delete the news data from the Realtime Database
			await deleteData(`website/news/${nid}`);

			return 'News deleted successfully!';
		} catch (error) {
			console.error('Error deleting the news:', error);
			throw new Error('Error deleting the news. Please try again.');
		}
	};

	if(loading) return <Loading />

  if (!newsDetails && !loading) 
    return <div>No Data Found</div>; 

	return (
		<div>
			<div className='SectionTitle'>{newsDetails.title}</div>
			<img className='ImgContent' width={500} src={newsDetails.image} alt={newsDetails.title} />
			<div className='WelcomeBtns MarginAuto'>
				<button className='DeleteBtn' onClick={handleDelete}>Delete</button>
			</div>
		</div>
	);
}

export default NewsPage;
