import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getData } from '../Fetch';
import "./Auth.css"
import { useNavigate } from 'react-router-dom';

const Auth = ({ setIsAuth, setAdminData }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential?.user;

      if (user) {
        const adminData = await getData(`admins/${user.uid}`);

        if (adminData) {
          setIsAuth(true);
          setAdminData(adminData);

          localStorage.setItem('aid', user.uid);

          setLoading(false)
          alert('Login successful!');
          navigate("/")
        } else {
          // Admin data not found, handle accordingly (e.g., redirect to an error page)
          alert('Admin data not found.');
          localStorage.removeItem('aid');

          await signOut(auth);
          
          setLoading(false)
          setIsAuth(false);
          setAdminData(null);
        }
      } else {
        // User is null, login credentials are invalid
        setLoading(false)
        alert('Invalid email or password. Please try again.');
        setIsAuth(false);
        setAdminData(null);
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle other errors (e.g., network issues) as needed
      setLoading(false)
      alert('An error occurred during login. Please try again later.');
      setIsAuth(false);
      setAdminData(null);
    }
  };

  return (
    <div>
      <div className='AuthTitle'>Admin Login</div>
      <form className='AuthContainer' onSubmit={handleLogin}>
        <input
        className='AuthInput'
        placeholder='Email'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <br />
        <input
        className='AuthInput'
        placeholder='Password'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <br />
        <button className='AppBtn MarginAuto' type="submit">{loading ? "Logging in..." : "Login"}</button>
      </form>
    </div>
  );
};

export default Auth;
