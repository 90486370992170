import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getData, putData, deleteUser } from '../../Fetch';
import "./UsersProfile.css"
import Loading from '../../Components/Loading';

function TraineeProfile() {
	const { tid } = useParams();
	const navigate = useNavigate();

	const [traineeData, setTraineeData] = useState(null);

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [level, setLevel] = useState('');
	const [levelsNo, setLevelsNo] = useState('');
	const [isDirty, setIsDirty] = useState(false);
	const [adminEmail, setAdminEmail] = useState('');
	const [myData, setMyData] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchTraineeData = async () => {
			try {
				const traineeData = await getData(`users/${tid}`);
				const levels = await getData(`website/levels`);

				if (traineeData && levels) {
					const admin = await getData(`admins/${traineeData.aid}`);
					const myData = await getData(`admins/${localStorage.getItem("aid")}`);
					setTraineeData(traineeData);
					setName(traineeData.name);
					setPhone(traineeData.phone);
					setLevel(traineeData.level);
					setLevelsNo(Object.keys(levels).length);
					setAdminEmail(admin ? admin.email : '-');
					setMyData(myData)
					setLoading(false)
				} else {
					console.log('Trainee not found or levels not available');
					navigate('/trainees');
					setLoading(false)
				}
			} catch (error) {
				console.error('Error fetching trainee data:', error);
				setLoading(false)
			}
		};

		fetchTraineeData();
	}, [tid, navigate]);

	const handleSave = async (e) => {
		e.preventDefault(); // Prevent the default form submission

		setLoading(true)

		const isFormValid = validateForm();

		if (!isFormValid) {
			alert('Please fill in all required fields (Name, Phone, Level) before saving.');
			setLoading(false)
			return;
		}

		const confirmed = window.confirm('Are you sure you want to save changes?');

		if (confirmed) {
			try {
				const aid = traineeData.aid;
				await putData(`users/${tid}`, { name, phone, level, aid });
				setIsDirty(false);
				alert('Changes saved successfully!');
				setLoading(false)
			} catch (error) {
				console.error('Error saving changes:', error);
				alert('Error saving changes. Please try again.');
				setLoading(false)
			}
		}
		setLoading(false)
	};

	const handleCancel = () => {
		if (isDirty) {
			const discardChanges = window.confirm('Discard changes and go back?');

			if (discardChanges) {
				navigate("/trainees");
			}
		} else {
			navigate("/trainees");
		}
	};

	const handleDelete = async () => {
		const canDelete = shouldAllowDelete();

		if (canDelete) {
			const confirmed = window.confirm('Are you sure you want to delete this trainee?');

			setLoading(true)
			
			if (confirmed) {
				try {
					// Delete trainee from the database
					const response = await deleteUser(tid);

					if (response && response.success) {
						alert('User deleted successfully!');
						navigate('/trainees');
						setLoading(false)
					} else {
						alert('Error deleting user. Please try again.');
						setLoading(false)
					}
				} catch (error) {
					console.error('Error deleting user:', error);
					alert('Error deleting user. Please try again.');
					setLoading(false)
				}
			}
			setLoading(false)
		} else {
			alert('You do not have permission to delete this user.');
			setLoading(false)
		}
	};

	const shouldAllowDelete = () => {
		// Check conditions for enabling the delete button
		return (
			myData.role === 'super' || (traineeData &&
			traineeData.aid === localStorage.getItem('aid'))
		);
	};

	const handleNameChange = (e) => {
		setName(e.target.value);
		setIsDirty(true);
	};

	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
		setIsDirty(true);
	};

	const handleLevelChange = (e) => {
		setLevel(e.target.value);
		setIsDirty(true);
	};

	const validateForm = () => {
		// Check if all required fields are filled
		return name.trim() !== '' && phone.trim() !== '' && level.trim() !== '';
	};

	if(loading) return <Loading />

	return (
		<div>
			<div className='SectionTitle'>Trainee Profile</div>
			<form onSubmit={handleSave}>
				{traineeData ? (
					<div>
						<div className='UserFieldsContainer'>
							<div className='UserField'>
								<label htmlFor="Name">Name:</label>
								<input type="text" name="Name" value={name} onChange={handleNameChange} required />
							</div>
							<div className='UserField'>
								<label htmlFor="Phone">Phone:</label>
								<input type="text" name="Phone" value={phone} onChange={handlePhoneChange} required />
							</div>
							<div className='UserField'>
								<label htmlFor="Level">Level:</label>
								<select name="Level" value={level} onChange={handleLevelChange} required>
									<option value="">Select Level</option>
									{Array.from({ length: levelsNo }, (_, index) => (
										<option key={index + 1} value={index + 1}>
											{index + 1}
										</option>
									))}
								</select>
							</div>
							<div className='UserField'>
								<label htmlFor="admin">Trainer: </label>
								<span>{adminEmail}</span>
							</div>
							<div className='UserField'>
								<label htmlFor="Id">ID:</label>
								<span>{tid}</span>
							</div>
						</div>
						<div className='WelcomeBtns MarginAuto'>
						<button type="button" onClick={handleCancel}>Cancel</button>
						<button type="submit" className={!isDirty?'DisabledBtn':'DeleteBtn'} disabled={!isDirty}>Save</button>
						</div>
						{shouldAllowDelete() &&
							<div className='FlexColumn MarginTop'>
								<button className='DeleteBtn AppBtn' type="button" onClick={handleDelete}>
									Delete Trainee
								</button>
							</div>
						}
					</div>
				) : (
					<p>Loading trainee data...</p>
				)}
			</form>
		</div>
	);
}

export default TraineeProfile;
