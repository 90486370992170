import React, { useEffect, useState } from 'react';
import { putData } from '../Fetch';
import axios from 'axios';

import "./Welcome.css";

const Token = 'dd5a525f6c393f9d177696acd54d5dd4';

export default function Welcome({ welcomeVideoData }) {
	const [videoLink, setVideoLink] = useState('');
	const [videoInfo, setVideoInfo] = useState('');
	const [statusMessage, setStatusMessage] = useState('');

	useEffect(() => {
		if (welcomeVideoData) {
			setVideoInfo(welcomeVideoData);
			setVideoLink(welcomeVideoData?.video);
		}
	}, [welcomeVideoData]);

	const handleUpdateWelcomeVideo = async () => {
		try {
			const fileInput = document.createElement('input');
			fileInput.type = 'file';
			fileInput.accept = 'video/*';
			fileInput.click();

			fileInput.addEventListener('change', async (event) => {
				const videoFile = event.target.files[0];

				if (videoFile) {
					try {
						// Step 1: Delete the old video from Vimeo
						if (videoInfo?.id) {
							setStatusMessage('Deleting the old video...');
							try {
								await axios.delete(
									`https://api.vimeo.com/videos/${videoInfo.id}`,
									{
										headers: {
											Authorization: `Bearer ${Token}`,
										}
									}
								);
							} catch (deleteError) {
								console.error('Error deleting old video:', deleteError);
								setStatusMessage('Failed to delete old video, continuing with upload...');
							}
						}

						// Step 2: Get upload link from Vimeo
						setStatusMessage('Getting upload link from Vimeo...');
						const uploadLinkResponse = await axios.post(
							'https://api.vimeo.com/me/videos',
							{
								upload: {
									approach: 'tus',
									size: videoFile.size
								}
							},
							{
								headers: {
									Authorization: `Bearer ${Token}`,
									'Content-Type': 'application/json'
								}
							}
						);

						const uploadLink = uploadLinkResponse.data.upload.upload_link;

						// Step 3: Upload the video file to Vimeo using the upload link with progress tracking
						setStatusMessage('Uploading video...');
						await axios.patch(uploadLink, videoFile, {
							headers: {
								'Content-Type': 'application/offset+octet-stream',
								'Upload-Offset': 0,
								'Tus-Resumable': '1.0.0',
							},
							onUploadProgress: (progressEvent) => {
								const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
								setStatusMessage(`Uploading video: ${percentCompleted}%`);
							}
						});

						const videoUri = uploadLinkResponse.data.uri;
						const videoId = videoUri.split('/videos/').pop();
						const videoLink = `https://player.vimeo.com/video/${videoId}`;

						// Step 4: Set the video name on Vimeo
						setStatusMessage('Setting video name...');
						const videoName = 'welcome-video';
						await axios.patch(
							`https://api.vimeo.com${videoUri}`,
							{
								name: videoName
							},
							{
								headers: {
									Authorization: `Bearer ${Token}`,
									'Content-Type': 'application/json'
								}
							}
						);

						// Step 5: Save the video link to Firebase Realtime Database
						setStatusMessage('Saving video link to database...');
						const path = 'website/welcome';
						await putData(`${path}`, { video: videoLink, id: videoId });

						setVideoLink(videoLink);
						alert('Video is uploaded successfully, please wait for server side optimizations.');
						setStatusMessage(null);
					} catch (error) {
						console.error('Error uploading video:', error);
						alert('Error uploading video');
					}
				} else {
					console.error('Please select a video file.');
					alert('Please select a video file.');
				}
			});
		} catch (error) {
			console.error('Error handling video upload:', error);
			alert('Error uploading video');
		}
	};

	return (
		<div className='Welcome'>
			<div className='WelcomeTitle'>
				<div>
					<div>ALL FOR ONE & ONE FOR ALL</div>
				</div>
			</div>

			<div className='WelcomeVideo'>
				{videoLink && (
					<iframe
						src={videoLink}
						allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
						title="welcome-video"
						style={{ width: '100%', height: '100%', display: 'block', border: 'none', borderRadius: 'min(15px, calc(100vw * (7 / 950)))' }}
					></iframe>
				)}
			</div>

			<div className='WelcomeBtns'>
				<button onClick={handleUpdateWelcomeVideo} disabled={statusMessage !== ''}>Upload Video</button>
				{statusMessage && <div style={{ color: "white" }}>{statusMessage}</div>}
			</div>
		</div>
	);
}