import React, { useState, useEffect } from 'react';
import { getData, putData } from '../../Fetch';
import { Link } from 'react-router-dom';
import Welcome from "../../Components/Welcome"
import Loading from '../../Components/Loading';
import Course from '../../Components/Course';
import News from '../../Components/News';

function HomePage() {
	const [welcomeVideo, setWelcomeVideo] = useState('');
	const [levels, setLevels] = useState({});
	const [loading, setLoading] = useState(false);
	const [news, setNews] = useState({});


	useEffect(() => {
		window.scrollTo(0, 0)
		const fetchData = async () => {
			try {
				const data = await getData('website');
				setWelcomeVideo(data.welcome);
				setLevels(data.levels);
				setNews(data.news);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			{loading && <Loading />}
			{!loading && (
				<div>
					{welcomeVideo && <Welcome welcomeVideoData={welcomeVideo} />}
					{news && <News newsData={news} />}
					{levels && <Course levelsData={levels} />}
				</div>
			)}
		</div>
	);
}

export default HomePage;