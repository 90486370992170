import React, { useEffect, useRef, useState } from 'react';
import "./Course.css";
import { useNavigate } from 'react-router-dom';

export default function Course({ levelsData }) {
	const navigate = useNavigate()
	return (
		<div className='CourseLevels'>
			{/* <div className='CourseBlurCircle'></div> */}
			<div className='CourseTitle'>Knights System</div>
			{levelsData && Object.entries(levelsData)
				.sort((a, b) => a.order - b.order)
				.map(([levelId, level], index) => (
					<CourseLevel levelId={levelId} key={index} level={level} isLast={index === Object.keys(levelsData).length - 1} />
				))}
			<div className='WelcomeBtns CourseBtn'>
				<button onClick={() => navigate("./addLevel")}>Add New Level</button>
			</div>
		</div>
	);
}

function CourseLevel({ level, isLast, levelId }) {
	const navigate = useNavigate()

	const courseLevelContainerRef = useRef(null);
	const [imagesLoaded, setImagesLoaded] = useState(false);

	useEffect(() => {
		const numberLine = courseLevelContainerRef.current.querySelector('.NumberLine');
		const numberSquare = courseLevelContainerRef.current.querySelector('.NumberSquare');
		const containerHeight = courseLevelContainerRef.current.offsetHeight;

		// Get the computed width of NumberSquare
		const numberSquareWidth = parseFloat(window.getComputedStyle(numberSquare).width);

		// Set the height of the NumberLine dynamically based on the width of NumberSquare
		numberLine.style.height = isLast ? '0' : `${containerHeight - numberSquareWidth}px`;
	}, [isLast, imagesLoaded]);

	// Function to handle the image load event
	const handleImageLoad = () => {
		setImagesLoaded(true);
	};

	return (
		<div className='CourseLevelContainer' ref={courseLevelContainerRef}>
			<div className='LevelNumberContainer'>
				<div className='NumberSquare'>{level.order}</div>
				<div className='NumberLine'></div>
			</div>
			<div className='CourseLevelContent'>
				<div onClick={() => navigate(`./lvl/${levelId}`)} className='CourseLevelTitle'>{level.title}</div>
				<div className='CourseLevelBody'>{level.body}</div>
				<div className="ThumbnailLockContainer">
					<img
						onClick={() => navigate(`./lvl/${levelId}`)} 
						className='CourseLevelThumbnail'
						src={level.thumbnail}
						alt={level.thumbnail}
						onLoad={handleImageLoad}
					/>
				</div>
			</div>
		</div>
	);
}
